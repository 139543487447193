<template>
  <div>
    <div :id="id" style="position: relative;">
      <vue-pdf v-bind="pdfProps" @loaded="onPdfLoad" ref="pdfContainer" />
      <div
        v-for="(layer, index) in layers"
        :key="index"
        :ref="el => (layersContainer[index] = el)"
        class="text-container"
        :class="layer.type != 'textBlock' ? 'center' : ''"
        :style="calculateStyle(index)"
        @mouseenter="layer.showHandlesOnHover = true"
        @mouseleave="layer.showHandlesOnHover = false"
        v-show="layer.active"
        :data-token="layer.name"
      >
        <span v-if="layer.useTextAsHtml" v-html="layer.text"></span>
        <span v-else>{{ layer.text }}</span>

        <template v-show="layer.showHandlesOnHover">
          <div
            v-show="layer.useRotationHandle"
            class="rotation-handle-increment"
            @click="incrementRotation(index)"
          ><v-icon small left class="iconColor">{{icons.mdiRotateRight}}</v-icon></div>
          <div
            v-show="layer.useRotationHandle"
            class="rotation-handle-decrement"
            @click="decrementRotation(index)"
          ><v-icon small left class="iconColor">{{icons.mdiRotateLeft}}</v-icon></div>
          <div
            v-show="layer.useWidthHandle"
            class="width-handle"
            :ref="el => (widthHandle[index] = el)"
          ></div>
          <div
            v-show="layer.useWidthAndHeightHandle"
            class="width-and-height-handle"
            :ref="el => (widthAndHeightHandle[index] = el)"
          ></div>
          <div
            v-show="layer.useFontHandle"
            class="font-handle-increment"
            @click="incrementFontSize(index)"
          ><v-icon small left class="iconColor">{{icons.mdiPlusBox}}</v-icon></div>
          <div
            v-show="layer.useFontHandle"
            class="font-handle-decrement"
            @click="decrementFontSize(index)"
          ><v-icon small left class="iconColor">{{icons.mdiMinusBox}}</v-icon></div>
        </template>
      </div>

      <div
        class="areaToCutLeft"
        :style="{
          width: css.cutWidth + 'px',
          height: '100%',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
      <div
        class="areaToCutRight"
        :style="{
          width: css.cutWidth + 'px',
          height: '100%',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
      <div
        class="areaToCutTop"
        :style="{
          width: '100%',
          height: css.cutWidth + 'px',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
      <div
        class="areaToCutBottom"
        :style="{
          width: '100%',
          height: css.cutWidth + 'px',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
    </div>
    <div v-if="srcLocale">
      Die Druckversion kann leicht abweichen. <a href="javascript:void(0)" @click="openDialog">Hier ansehen.</a>
      <v-dialog
        v-model="showDialog"
        width="900px"
      >
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Druckversion</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showDialog = false">
              <v-icon size="18" >
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <vue-pdf :src="printVersionSrc" :key="printVersionKey"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              outlined
              @click="showDialog = false"
            >
              Schließen
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <template
      v-for="(layer, index) in layers"
    >
      <v-checkbox
        v-if="layer.activeToggle"
        class="checkbox"
        v-model="layers[index].active"
        dense
        :label="layer.toggleLabel ? layer.toggleLabel : layer.name "
        hide-details
        @click="emitCoordinates"
      ></v-checkbox>

      <template v-if="layers[index].active && layers[index].qrcodeContentHandler">
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="layers[index].qrcodeContentType"
            :items="qrcodeContentTypes"
            item-text='label'
            item-value='value'
            label="QR-Code Typ"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <v-text-field
            v-model="layers[index].text"
            label="QR-Code Inhalt"
            type="text"
            outlined
            dense
            hide-details="auto"
            placeholder="QR-Code Inhalt"
            required
            :rules="[stringRules]"
            @change="emitCoordinates"
          ></v-text-field>
        </v-col>
      </template>
      <template v-if="layers[index].active && layers[index].dataPrivacyContentHandler">
        <v-col
          cols="12"
          md="12"
        >
          <v-textarea
            class="mt-3"
            maxlength="300"
            v-model="layers[index].text"
            label="Datenschutztext"
            type="text"
            outlined
            rows="5"
            dense
            hide-details="auto"
            required
            :rules="[stringRules]"
            @change="emitCoordinates"
          ></v-textarea>
        </v-col>
      </template>
    </template>
  </div>
</template>

<script>
import VuePdf from 'vue-pdf';
import interact from 'interactjs';
import { ref, onMounted, defineComponent, getCurrentInstance, watch, nextTick } from '@vue/composition-api';
import {
  mdiPlusBoxOutline,
  mdiMinusBoxOutline,
  mdiPlusBox,
  mdiMinusBox,
  mdiRotateLeft,
  mdiRotateRight,
  mdiArrowSplitVertical,
  mdiArrowBottomRight,
  mdiClose,
} from '@mdi/js'
import axios from "axios";

export default defineComponent({
  name: 'VuePdfWithDraggable',
  components: {
    VuePdf,
  },
  props: {
    ...VuePdf.props,
    id: {
      type: String,
      default: "datei"
    },
    layers: {
      type: Array,
      default: () => []
    },
    srcLocale: {
      type: String,
      default: ""
    },
    pageHeight: {
      type: Number,
      validator(value) {
        return Number.isFinite(value) && Number.isInteger(value);
      }
    },
    pageWidth: {
      type: Number,
      validator(value) {
        return Number.isFinite(value) && Number.isInteger(value);
      }
    },
  },
  emits: ['update:coordinates'],
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const pdfProps = ref({
      ...props
    });

    console.log(props.id,'props',props)

    const layersCss = ref([]);
    const layersContainer = ref([]); // Array von Refs

    props.layers.forEach((layer,index) => {
      layersContainer.value[index] = null;
      layersCss.value[index] = {
        active: true,
        posYInPX: 0,
        posXInPX: 0,
        widthInPX: 0,
        fontSizeInPX: 0,
        lineHeightInPX: 0,
        rotationInDeg: 0,
        paddingLeftInPX: 0,
        paddingRightInPX: 0,
        paddingTopInPX: 0,
        paddingBottomInPX: 0,
      };
    })

    const widthHandle = ref([]); // Array von Refs
    const widthAndHeightHandle = ref([]); // Array von Refs
    const pdfDimensions = ref({ width: 0, height: 0 });
    const pdfContainer = ref(null);
    const showDialog = ref(false);
    const coordinates = ref({});
    const cutInMM = 3;
    const css = ref({
      cutWidth: 0,
      cutHeight: 0,
    });
    const stringRules = (value) => !!value.trim() || 'Bitte ausfüllen';

    const qrcodeContentTypes = ref([
      {label:'Webseite',value:'website',prefix:''},
      {label:'Telefon',value:'telefon',prefix:'tel:'},
      {label:'E-Mail',value:'email',prefix:'mailto:'},
    ]);

    const onPdfLoad = async () => {
      const pdfComponent = vm.$refs.pdfContainer;
      if (pdfComponent && pdfComponent.pdf) {
        pdfComponent.$on('page-size', (width, height, scale) => {
          const actualWidth = width;
          const actualHeight = height;

          pdfDimensions.value = {
            width: actualWidth,
            height: actualHeight,
            scale: scale
          };

          props.layers.forEach((layer,index) => {
            convertUnits(index, props.pageWidth, props.pageHeight, actualWidth, actualHeight);
          });

          css.value.cutHeight = calculateInPX(cutInMM, props.pageHeight, actualHeight);
          css.value.cutWidth = calculateInPX(cutInMM, props.pageWidth, actualWidth);

          emitCoordinates();
        });

        pdfComponent.pdf.loadPage(1);
      } else {
        console.error('Failed to load PDF');
      }
    };

    const calculateInPX = (valueInMM, pageDimension, actualDimension) => {
      return (valueInMM / pageDimension) * actualDimension / window.devicePixelRatio;
    };

    const calculateInMM = (valueInPX, pageDimension, actualDimension) => {
      return (valueInPX * pageDimension) / (actualDimension / window.devicePixelRatio);
    };

    const convertUnits = (index, pageWidth, pageHeight, actualWidth, actualHeight) => {
      layersCss.value[index].rotationInDeg = props.layers[index].rotationInDeg || 0;
      layersCss.value[index].posXInPX = calculateInPX(props.layers[index].posXInMM, pageWidth, actualWidth);
      layersCss.value[index].posYInPX = calculateInPX(props.layers[index].posYInMM, pageHeight, actualHeight);
      layersCss.value[index].widthInPX = calculateInPX(props.layers[index].widthInMM, pageWidth, actualWidth);
      layersCss.value[index].fontSizeInPX = calculateInPX(props.layers[index].fontSizeInMM, pageHeight, actualHeight);
      layersCss.value[index].paddingLeftInPX = calculateInPX(props.layers[index].paddingLeftInMM, pageWidth, actualWidth);
      layersCss.value[index].paddingRightInPX = calculateInPX(props.layers[index].paddingRightInMM, pageWidth, actualWidth);
      layersCss.value[index].paddingTopInPX = calculateInPX(props.layers[index].paddingTopInMM, pageHeight, actualHeight);
      layersCss.value[index].paddingBottomInPX = calculateInPX(props.layers[index].paddingBottomInMM, pageHeight, actualHeight);
      layersCss.value[index].lineHeightInPX = calculateInPX(props.layers[index].lineHeightInMM, pageHeight, actualHeight);

      if(props.layers[index].type == 'qrcode'){
        layersCss.value[index].heightInPX = layersCss.value[index].widthInPX;
        layersCss.value[index].zIndex = index + 1;

      }
      else if(props.layers[index].type == 'shape'){
        layersCss.value[index].heightInPX = calculateInPX(props.layers[index].heightInMM, pageWidth, actualWidth);
        layersCss.value[index].zIndex = 0;
      }
      else{
        layersCss.value[index].heightInPX = 'auto';
        layersCss.value[index].zIndex = index + 1;
      }
    };

    const calculateStyle = (index) => {

      const style = {
        position: `absolute`,
        zIndex: `${layersCss.value[index].zIndex}`,
        top: `${layersCss.value[index].posYInPX}px`,
        left: `${layersCss.value[index].posXInPX}px`,
        width: `${layersCss.value[index].widthInPX}px`,
        height: `${layersCss.value[index].heightInPX}px`,
        paddingLeft: `${layersCss.value[index].paddingLeftInPX}px`,
        paddingRight: `${layersCss.value[index].paddingRightInPX}px`,
        paddingTop: `${layersCss.value[index].paddingTopInPX}px`,
        paddingBottom: `${layersCss.value[index].paddingBottomInPX}px`,
        transform: `rotate(${layersCss.value[index].rotationInDeg}deg)`,
        cursor: props.layers[index].useMovement ? 'move' : 'default'
      };

      if(layersCss.value[index].fontSizeInPX > 0){
        style.fontSize = `${layersCss.value[index].fontSizeInPX}px`;
      }

      if(layersCss.value[index].lineHeightInPX > 0){
        style.lineHeight = `${layersCss.value[index].lineHeightInPX}px`;
      }

      return style;
    };

    const emitCoordinates = () => {
      let coordinateItem = {};
      coordinates.value = props.layers.map((layer,index) => {

        coordinateItem = {
          type: props.layers[index].type,
          posXInMM: calculateInMM(layersCss.value[index].posXInPX, props.pageWidth, pdfDimensions.value.width),
          posYInMM: calculateInMM(layersCss.value[index].posYInPX, props.pageHeight, pdfDimensions.value.height),
          fontSizeInMM: calculateInMM(layersCss.value[index].fontSizeInPX, props.pageHeight, pdfDimensions.value.height),
          paddingLeftInMM: calculateInMM(layersCss.value[index].paddingLeftInPX, props.pageWidth, pdfDimensions.value.width),
          paddingRightInMM: calculateInMM(layersCss.value[index].paddingRightInPX, props.pageWidth, pdfDimensions.value.width),
          paddingTopInMM: calculateInMM(layersCss.value[index].paddingTopInPX, props.pageHeight, pdfDimensions.value.height),
          paddingBottomInMM: calculateInMM(layersCss.value[index].paddingBottomInPX, props.pageHeight, pdfDimensions.value.height),
          lineHeightInMM: calculateInMM(layersCss.value[index].lineHeightInPX, props.pageHeight, pdfDimensions.value.height),
          widthInMM: calculateInMM(layersCss.value[index].widthInPX, props.pageWidth, pdfDimensions.value.width),
          text: layer.text,
          rotationInDeg: layersCss.value[index].rotationInDeg,
          pageWidth: props.pageWidth,
          pageHeight: props.pageHeight,
        };

        if(props.layers[index].type == 'shape'){
          coordinateItem.heightInMM = calculateInMM(layersCss.value[index].heightInPX, props.pageHeight, pdfDimensions.value.height)
        }

        let optionalLayerProps = [
          'active',
          'activeToggle',
          'toggleLabel',
          'name',
          'requiredRequest',
          'useSquareContainer',
          'useMovement',
          'useFontHandle',
          'useWidthHandle',
          'useWidthAndHeightHandle',
          'useRotationHandle',
          'useTextAsHtml',
          'qrcodeContentHandler',
          'dataPrivacyContentHandler',
          'qrcodeContentType',
          'qrcodeContent',
          'border',
        ];
        optionalLayerProps.forEach((prop) => {
          if (typeof props.layers[index][prop] !== 'undefined') {
            coordinateItem[prop] = props.layers[index][prop];
          }
        });

        return coordinateItem;
      });
      emit('update:coordinates', coordinates.value);
    };

    const incrementRotation = (index) => {
      layersCss.value[index].rotationInDeg = (layersCss.value[index].rotationInDeg + 90) % 360;
      layersContainer.value[index].style.transform = `rotate(${layersCss.value[index].rotationInDeg}deg)`;
      emitCoordinates();
    };

    const decrementRotation = (index) => {
      layersCss.value[index].rotationInDeg = (layersCss.value[index].rotationInDeg - 90) % 360;
      layersContainer.value[index].style.transform = `rotate(${layersCss.value[index].rotationInDeg}deg)`;
      emitCoordinates();
    };

    const incrementFontSize = (index) => {
      layersCss.value[index].fontSizeInPX += 1;
      layersCss.value[index].lineHeightInPX += 1;
      layersContainer.value[index].style.fontSize = `${layersCss.value[index].fontSizeInPX}px`;
      layersContainer.value[index].style.lineHeight = `${layersCss.value[index].lineHeightInPX}px`;
      emitCoordinates();
    };

    const decrementFontSize = (index) => {
      layersCss.value[index].fontSizeInPX -= 1;
      layersCss.value[index].lineHeightInPX -= 1;
      layersContainer.value[index].style.fontSize = `${layersCss.value[index].fontSizeInPX}px`;
      layersContainer.value[index].style.lineHeight = `${layersCss.value[index].lineHeightInPX}px`;
      emitCoordinates();
    };

    const resizeWidth = (event,index) => {
      const deltaX = event.dx;
      layersCss.value[index].widthInPX += deltaX;
      layersContainer.value[index].style.width = `${layersCss.value[index].widthInPX}px`;
      emitCoordinates();
    };

    const resizeWidthAndHeight = (event,index) => {
      const deltaX = event.dx;
      layersCss.value[index].widthInPX += deltaX;
      layersContainer.value[index].style.width = `${layersCss.value[index].widthInPX}px`;
      layersCss.value[index].heightInPX = layersCss.value[index].widthInPX;
      layersContainer.value[index].style.height = `${layersCss.value[index].heightInPX}px`;
      emitCoordinates();
    };

    const printVersionSrc = ref(JSON.parse(JSON.stringify(props.src)))
    const printVersionKey = ref(0);

    const openDialog = () => {
      emitCoordinates();
      const fd = new FormData();
      fd.append('aktion',"showPrintVersionPdf");
      fd.append('src',props.srcLocale);
      fd.append('ebenenEinstellungen',JSON.stringify(coordinates.value));
      fd.append('addQrcode',1);
      fd.append('showExampleRecipient',1);

      axios
        .post('/api/ajaxServer/',fd)
        .then(response => {
          if (response.data.valid) {
            showDialog.value = true;
            printVersionSrc.value = response.data.src;
            printVersionKey.value++;
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
        })
        .catch(error => {
          vm.$toastr.e(error,error);
        })
    };

    let previousRatio = window.devicePixelRatio;

    window.addEventListener('resize', () => {
      if (window.devicePixelRatio !== previousRatio) {
        emitCoordinates();
        previousRatio = window.devicePixelRatio;
      }
    });

    onMounted(() => {

      nextTick(() => {
        let refs = document.querySelectorAll(`#${props.id} .text-container`);
        layersContainer.value = Array.from(refs);

        refs = document.querySelectorAll(`#${props.id} .width-handle`);
        widthHandle.value = Array.from(refs);

        refs = document.querySelectorAll(`#${props.id} .width-and-height-handle`);
        widthAndHeightHandle.value = Array.from(refs);

        props.layers.forEach((layer, index) => {

          if (layer.useMovement) {
            layersContainer.value[index].setAttribute('data-x', layersCss.value[index].posXInPX);
            layersContainer.value[index].setAttribute('data-Y', layersCss.value[index].posYInPX);

            interact(layersContainer.value[index])
              .draggable({
                onmove: (event) => dragMoveListener(event, index),
              })
              .styleCursor(false)
              .styleCursor({
                touchAction: 'none',
              });

            interact(widthHandle.value[index])
              .draggable({
                onmove: (event) => resizeWidth(event, index),
              })
              .styleCursor(false);

            interact(widthAndHeightHandle.value[index])
              .draggable({
                onmove: (event) => resizeWidthAndHeight(event, index),
              })
              .styleCursor(false);

            }
        });
      });


    });

    const dragMoveListener = (event,index) => {
      const target = event.target;
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      const pdfRect = pdfContainer.value.$el.getBoundingClientRect();
      const textRect = target.getBoundingClientRect();

      let newX = x;
      let newY = y;

      const offsetX = (cutInMM / props.pageWidth) * pdfRect.width;
      const offsetY = (cutInMM / props.pageHeight) * pdfRect.height;

      if (textRect.left + event.dx < pdfRect.left + offsetX) {
        newX = pdfRect.left - textRect.left + x + offsetX;
      } else if (textRect.right + event.dx > pdfRect.right - offsetX) {
        newX = pdfRect.right - textRect.right + x - offsetX;
      }

      if (textRect.top + event.dy < pdfRect.top + offsetY) {
        newY = pdfRect.top - textRect.top + y + offsetY;
      } else if (textRect.bottom + event.dy > pdfRect.bottom - offsetY) {
        newY = pdfRect.bottom - textRect.bottom + y - offsetY;
      }

      target.style.left = `${newX}px`;
      target.style.top = `${newY}px`;
      target.style.transform = `rotate(${css.value.rotationInDeg}deg)`;

      target.setAttribute('data-x', newX);
      target.setAttribute('data-y', newY);

      layersCss.value[index].posXInPX = newX;
      layersCss.value[index].posYInPX = newY;
      emitCoordinates();
    };

    return {
      pdfProps,
      pdfDimensions,
      pdfContainer,
      showDialog,
      printVersionSrc,
      printVersionKey,
      incrementRotation,
      decrementRotation,
      incrementFontSize,
      decrementFontSize,
      calculateStyle,
      openDialog,
      onPdfLoad,
      layersContainer,
      widthHandle,
      widthAndHeightHandle,
      css,
      layersCss,
      qrcodeContentTypes,
      emitCoordinates,
      stringRules,
      icons: {
        mdiPlusBoxOutline,
        mdiMinusBoxOutline,
        mdiPlusBox,
        mdiMinusBox,
        mdiRotateLeft,
        mdiRotateRight,
        mdiArrowSplitVertical,
        mdiArrowBottomRight,
        mdiClose,
      },
    };
  },
});
</script>

<style>
.text-container {
  background-color: white;
  fontFamily: 'helvetica';
  cursor: move;
  user-select: none;
  color: #000;
  position:absolute;
  top:0px;
  left:0px;
  border: 0.1px dashed #c62323;
}
.text-container.center{
  display:flex;
  align-items: center;
  justify-content: center;
}
.rotation-handle-increment {
  position: absolute;
  left: -10px;
  top: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  user-select: none;
}
.rotation-handle-decrement {
  position: absolute;
  right: -10px;
  bottom: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  user-select: none;
}
.font-handle-increment {
  position: absolute;
  left: 0px;
  bottom: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.font-handle-decrement {
  position: absolute;
  left: 13px;
  bottom: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.width-handle{
  position: absolute;
  right: -10px;
  top: 10%;
  width: 10px;
  height: 20px;
  background-color: transparent;
  cursor: ew-resize;
  font-size: 14px;
  color: #fff;
  user-select: none;
}
.width-and-height-handle {
  position: absolute;
  right: -7px;
  bottom: -16px;
  width: 10px;
  height: 20px;
  background-color: transparent;
  cursor: se-resize;
  font-size: 14px;
  color: #fff;
  user-select: none;
}
.text-container:hover .rotation-handle-increment,
.text-container:hover .width-handle,
.text-container:hover .width-and-height-handle {
  display: block;
}
.areaToCutLeft,
.areaToCutRight,
.areaToCutTop,
.areaToCutBottom{
  position: absolute;
  background: red;
  box-sizing: border-box;
  background-repeat: repeat-y;
}
.areaToCutLeft{
  left:0;
  top:0;
}
.areaToCutRight{
  right:0;
  top:0;
}
.areaToCutTop{
  top:0;
  left:0;
}
.areaToCutBottom{
  bottom:0;
  left:0;
}
.iconColor{
  color:red !important;
  opacity:1 !important;
}
</style>
